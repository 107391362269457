import { useEffect, useState } from "react";
import {
    Container,
    Stack,
    Card,
    Typography,
    TextField,
    Box,
} from "@mui/material";
import Rating from 'rating-scale-with-rtl';
import { FormProvider } from "react-hook-form";

const FeedbackPage = () => {
    return (
        <Container>
            <Stack height="100vh" justifyContent="center" alignItems="center">
                {/*<FormProvider {...methods}>
                    <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmitHandler)}
    >
                    <Card sx={{ padding: 6 }}>
                        <Rating rtl={false} length={5} colorBackground="#e4e5e9" colorRating="#ffc107" width='75px' height='20px' />
                        <LoadingButton
                        fullWidth
                        type="submit"
                        sx={{ mt: 2 }}
                        variant="contained"
                        loading={verifyState.isLoading}
                        >
                        Send
    </LoadingButton>
                    </Card>
                    {/*</Box>
                </FormProvider>*/}
            </Stack>
        </Container>
        );
    };
  
  export default FeedbackPage;
  