/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Usergear = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`usergear ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10.6073 15C13.7139 15 16.2323 12.4816 16.2323 9.375C16.2323 6.2684 13.7139 3.75 10.6073 3.75C7.5007 3.75 4.9823 6.2684 4.9823 9.375C4.9823 12.4816 7.5007 15 10.6073 15Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M2.56354 18.7501C3.54909 17.5755 4.77995 16.6309 6.1696 15.9829C7.55925 15.3348 9.07396 14.999 10.6073 14.999C12.1406 14.999 13.6553 15.3348 15.045 15.9829C16.4346 16.6309 17.6655 17.5755 18.651 18.7501"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M21.1073 14.25C21.9357 14.25 22.6073 13.5784 22.6073 12.75C22.6073 11.9216 21.9357 11.25 21.1073 11.25C20.2789 11.25 19.6073 11.9216 19.6073 12.75C19.6073 13.5784 20.2789 14.25 21.1073 14.25Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M21.1073 11.25V10.125"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M19.8042 12L18.8386 11.4375"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M19.8042 13.5L18.8386 14.0625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M21.1073 14.25V15.375"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M22.4104 13.5L23.3761 14.0625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M22.4104 12L23.3761 11.4375"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
