import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import blogs_4 from "../assets/img/blogs4.png";


const UnveilingCodeGenie = () => {

  const navigate = useNavigate();

  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }
    return (
      <>
      
        <Container maxWidth="lg">
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <img className="ellipse-18" alt="Ellipse" src={blogs_4} title="Code Genie: Unveiling Code Genie: Revolutionizing Smart Contract Audits in the Blockchain Era"/></div> <br></br>  <br></br>
            Unveiling Code Genie: Revolutionizing Smart Contract Audits in the Blockchain Era
            </Typography>
            <Typography textAlign="justify">
            The world of smart contracts is intricate and complex. Ensuring their flawlessness is paramount, not just for functionality but also to fortify them against potential breaches and vulnerabilities. Enter Code Genie – a groundbreaking AI tool that is redefining how we perceive smart contract audits.
            </Typography> <br></br>
            <Typography textAlign="justify">
            Harnessing the prowess of OpenAI's LLM model, Code Genie delves deep into the smart contract's code, offering an audit and rectification process that's lightning-fast, supremely accurate, and unbelievably easy. Whether you're a developer well-versed with coding or a newbie just stepping into the blockchain world, Code Genie's intuitive one-click approach allows for a seamless audit experience.
            </Typography> <br></br>
            <Typography textAlign="justify">
            What truly sets Code Genie apart is its expansive audit coverage. It touches upon essential aspects – from basic functionality and security to the intricacies of gas usage, visibility, and even potential exploit scenarios. And while traditional methods might gloss over minute details, Code Genie's Large Language Model AI dives deep, catching anomalies that even the most trained human eye could miss.
            </Typography> <br></br>
            <Typography textAlign="justify">
            The brilliance doesn't stop at detection. Upon identifying issues, Code Genie takes a proactive stance, attempting to rectify them in real-time. This not only ensures a robust contract but also significantly reduces the turnaround time for developers – a blessing in the rapidly evolving world of blockchain.
            </Typography> <br></br>
            <Typography textAlign="justify">
            The financial implications cannot be ignored either. In an industry where auditing costs can skyrocket, Code Genie emerges as an oasis of affordability. With potential savings ranging from $5,000 to over $25,000, Code Genie is not just a tool but an investment – one that promises immense returns in terms of security, efficiency, and savings.
            </Typography><br></br>
            <Typography textAlign="justify">
            For startups, developers, and agencies, Code Genie offers flexible plans tailored to diverse needs, including a free trial. The world of blockchain is dynamic, with stakes higher than ever. In such a scenario, a tool like Code Genie, which offers unparalleled accuracy, speed, and affordability, is not just an asset but a necessity.
            </Typography> <br></br>
            <Typography textAlign="justify">
            Embrace the future of smart contract audits with Code Genie. Experience precision, speed, and innovation like never before, and fortify your blockchain endeavors with unmatched assurance. Try Code Genie today and step into a new era of smart contract optimization.
            </Typography>
            {/* <Link
              to="/signin"
              style={{
                display: "flex",
                marginTop: 40,
                textDecoration: "none",
                color: "black",
              }}
            >
              <SvgIcon sx={{ mr: 1 }}>
                <KeyboardBackspace />
              </SvgIcon>
              Back to log in
            </Link> */}
            <Button variant="contained" sx={{ mr: 2, float: 'right', margin: '8px' }}
          onClick={handleLearnMore} 
          >
            Learn More<KeyboardArrowRight/>
          </Button>
          </Box>
        </Container>
      </>
    );
  };
  
  export default UnveilingCodeGenie;