import React, { useEffect, useState } from 'react';
import { useGetOrderSuccessQuery } from '../../redux/api/subscriptionApi';
import { Container, Stack, Typography, Button } from '@mui/material';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { setModule } from '../../redux/features/genieSlice';
import { useSigninUserMutation } from '../../redux/api/authApi';
import { userApi } from '../../redux/api/userApi';
import Dashboard_tm from '../traffic_monster/dashboard_tm';
import { useVerifyCouponQuery } from '../../redux/api/subscriptionApi';
import { toast } from "react-toastify";

const ReferrealProcess = () => {
    const navigate = useNavigate();
    // const siteUrl = window.location.search;
    // const urlParams = new URLSearchParams(siteUrl);
    //console.log( urlParams.get('session_id') ); // 159


    /* const dispatch = useAppDispatch();
    
    const campaignid = String(urlParams.get('campaignid')) || "";
    const subscription_level = urlParams.get("subscription_level") || "";
    const module = urlParams.get("module") || "";
    const { data, error, isSuccess } = useGetOrderSuccessQuery({ "session_id": String(urlParams.get('session_id')), "campaign_id":campaignid, "subscription_level":subscription_level, "module":module });
    console.log('success page data=>', data);

    useEffect(() => {
        if (isSuccess) {
            localStorage.setItem("payment", "Success");
            localStorage.setItem("subscription_level", subscription_level);

            const fromUpgrade = localStorage.getItem('fromUpgrade');
            const fromNewcampaign = localStorage.getItem('fromNewcampaign');

            if (localStorage.getItem('blogsCount') === 'true' && fromNewcampaign != 'true') {
                    navigate('/codegenie/generated-blogs'); 
            } else  if(fromNewcampaign === 'true'){
                localStorage.removeItem('fromNewcampaign');
                localStorage.setItem('campaignid', campaignid);
                localStorage.setItem('returningtocampaign', 'true');
                navigate('/codegenie/new-campaign');
            } else {
                navigate("/codegenie/dashboard");
            }

        }
    }, [isSuccess]);

    const getModule = urlParams.get("module");

    useEffect(() => {
        localStorage.removeItem('fromUpgrade');
        localStorage.removeItem('user_name');
    }, []) */

    const params = new URLSearchParams(window.location.search);
    const couponCode = params.get("coupon_code") || '';

    const { data: verifyCouponData, isSuccess: isCouponSuccess } = useVerifyCouponQuery({ coupon_code: couponCode });

    useEffect(() => {
        if (isCouponSuccess) {
            //   window.location = checkoutState.data?.checkout_url;
            //window.open(checkoutState.data?.checkout_url, '_blank');
            if (verifyCouponData.msg === 'Coupon is valid') {
                toast.success(verifyCouponData.msg);
                localStorage.setItem('appsumo_coupon_code', couponCode);
                navigate('/signup');
            }else{
                toast.error(verifyCouponData.msg);
            }
        }
    }, [isCouponSuccess, verifyCouponData, navigate]);


    return (
        <>
            <Container>
                <Stack height="100vh" justifyContent="center" alignItems="center">

                    <Typography variant='h3'>Verifying Your Coupon Code...
                    </Typography>
                    {/* <br></br>
                    <Button
                        sx={{
                            background:
                                "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                            color: "white",
                            borderRadius: "4px",
                            padding: "12px 16px",
                            height: "44px",
                        }}
                        onClick={() => {
                            // navigate("/codegenie/blogs-list");
                            navigate("/codegenie/dashboard");



                        }}
                    >
                        Dashboard
                    </Button> */}
                </Stack>
            </Container>

        </>)
}

export default ReferrealProcess;