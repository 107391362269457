import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useState } from "react";
import { Typography, Stack, IconButton, Collapse, Box } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import ItemsCarousel from 'react-items-carousel';
import { Bracketscurly2 } from "../assets/icons/Bracketscurly2";



const SliderItem = (props: { title: string; content: string, icon: any }) => {
  return (
    <>

{/* <div className="col-md-4 mb-2 text-white widthfull"  >
        <div className="back_border p-4 rounded-1 box_height">
          <div>
             {props.icon}
          </div>
          <h5 className="my-3"> {props.title}</h5>
          <p className="mb-0 fs-14">
          {props.content}
          </p>
        </div>
      </div> */}

<div className="text-white mx-2">
        <div className="back_border p-4 rounded-1 box_height">
          <div className="frame-19">
          {props.icon}
          </div>
          <h5 className="my-3">{props.title}</h5>
          <p className="mb-0 fs-14">
          {props.content}
          </p>
        </div>
      </div>



    </>
  );
};

export default SliderItem;