/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Paperplanetilt1 = ({ color = "white", className }: Props): JSX.Element => {
  return (
    <svg
      className={`paperplanetilt-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.2875 4.4875L2.9875 11.05C2.78916 11.1042 2.61235 11.2183 2.48117 11.3766C2.34999 11.535 2.27081 11.7299 2.25441 11.9349C2.23801 12.1398 2.2852 12.3449 2.38954 12.522C2.49388 12.6992 2.6503 12.8399 2.8375 12.925L13.5375 17.9875C13.7471 18.0846 13.9154 18.2529 14.0125 18.4625L19.075 29.1625C19.1601 29.3497 19.3008 29.5061 19.478 29.6105C19.6551 29.7148 19.8602 29.762 20.0651 29.7456C20.2701 29.7292 20.465 29.65 20.6234 29.5188C20.7817 29.3876 20.8958 29.2108 20.95 29.0125L27.5125 5.7125C27.5622 5.54247 27.5653 5.3622 27.5214 5.19057C27.4775 5.01895 27.3882 4.86229 27.263 4.73703C27.1377 4.61176 26.9811 4.5225 26.8094 4.4786C26.6378 4.4347 26.4575 4.43777 26.2875 4.4875V4.4875Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M13.8625 18.1375L19.5125 12.4875"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

Paperplanetilt1.propTypes = {
  color: PropTypes.string,
};
