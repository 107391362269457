import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout.page";
import { loadStripe } from "@stripe/stripe-js";
import { Container } from "@mui/material";

import { useGetApiKeyQuery, useLazyGetClientSecretQuery } from "../../redux/api/paymentApi";
import { setPayment } from "../../redux/features/paymentSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { string } from "zod";
import CheckoutPage from "./checkout.page";

function Payment() {
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState("");
   
  const getApiState = useGetApiKeyQuery();
  const [getClientSecret, getClientState] = useLazyGetClientSecretQuery();

  const paymentState = useAppSelector((state) => state.paymentState);

  useEffect(() => {
    if(getApiState.isSuccess){
      setStripePromise(loadStripe(getApiState.data?.publishableKey));
    }
  }, [getApiState]);

  /*useEffect(() => {
    getClientSecret({amount: Number(paymentState.payments?.amount), currency: String(paymentState.payments?.currency)});
  }, [getClientSecret, paymentState]);*/

  useEffect(() => {
    if(getClientState.isSuccess){
      setClientSecret(getClientState.data.client_secret);
    }
  }, [getClientState]);

  return (
    <>
      {stripePromise && (
        <Container>
            <Elements stripe={stripePromise}>
                <CheckoutPage />
            </Elements>
        </Container>
        
      )}
    </>
  );
}

export default Payment;
