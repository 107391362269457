import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import blogs_8 from "../assets/img/blogs8.png";


const   RealtimeRectifications = () => {

  const navigate = useNavigate();

  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }
    return (
      <>
      
        <Container maxWidth="lg">
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img className="ellipse-18" alt="Ellipse" src={blogs_8} title="Code Genie: Real-time Rectifications: Code Genie's Proactive Approach to Smart Contract Audits"/></div> <br></br>  <br></br>
            Real-time Rectifications: Code Genie's Proactive Approach to Smart Contract Audits
            </Typography>
            <Typography textAlign="justify">
            In the intricate web of blockchain technology, smart contracts have emerged as the pivotal touchpoint. Their seamless operation determines the efficacy and trustworthiness of blockchain applications. However, as vital as detection of issues is, the true mettle of an auditing tool lies in its rectification capabilities. Code Genie, a trailblazer in the blockchain auditing domain, exemplifies this paradigm by not only identifying but also rectifying smart contract issues in real-time. With the increasing search volume around "proactive smart contract solutions" and "real-time blockchain fixes", it's evident that the market seeks tools that do more than just highlight problems. Code Genie is perfectly positioned to meet this demand.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            The Dynamic Duo: Detection and Rectification
            </Typography>
            <Typography textAlign="justify">
            When smart contract flaws go undetected, they can lead to significant financial losses, data breaches, and erosion of trust. While several tools can identify these glitches, few can claim prowess in instantaneous rectification. Code Genie’s unique algorithm, infused with advanced AI capabilities, not only flags potential vulnerabilities but also provides on-the-spot solutions, making it an invaluable asset for anyone aiming for top-tier blockchain operations.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2}textAlign="justify">
            Bridging the Gap with AI-driven Insights
            </Typography>
            <Typography textAlign="justify">
            By leveraging the power of advanced artificial intelligence, Code Genie offers insights that transcend human capacity. AI-driven audits ensure a more thorough and nuanced understanding of smart contract intricacies. The real magic, however, lies in the platform's ability to use these insights to craft real-time solutions. Instead of developers spending countless hours deciphering and correcting issues, Code Genie simplifies the process, offering immediate fixes that are both efficient and reliable.
           </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            From Passive Detection to Proactive Rectification
            </Typography>
            <Typography textAlign="justify">
            Traditional smart contract audits have often been a passive process - you get a list of issues, and then the onus is on the developer to find solutions. Code Genie transforms this narrative. With its proactive approach, it not only detects issues but actively suggests rectifications. This not only saves precious time but also instills confidence, ensuring that the smart contract, once deployed, will function as intended without any hitches.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Why This Matters for the Future of Blockchain
            </Typography>
            <Typography textAlign="justify">
            The increasing adoption of blockchain across sectors, from finance to healthcare, means that the number of smart contracts in operation is set to skyrocket. In this bustling landscape, having an auditing tool that offers real-time rectifications is not just a luxury; it's a necessity. For businesses and developers seeking to maintain a competitive edge, ensure operational excellence, and foster trust among users, Code Genie's proactive auditing approach is the gold standard.
To sum up, in the ever-evolving realm of blockchain technology, where perfection and promptness are paramount, Code Genie is setting a new benchmark. Its commitment to real-time rectifications is not just reshaping smart contract audits; it's redefining what we should expect from our auditing tools.

            </Typography>
            {/* <Link
              to="/signin"
              style={{
                display: "flex",
                marginTop: 40,
                textDecoration: "none",
                color: "black",
              }}
            >
              <SvgIcon sx={{ mr: 1 }}>
                <KeyboardBackspace />
              </SvgIcon>
              Back to log in
            </Link> */}
            <Button variant="contained" sx={{ mr: 2, float: 'right', margin: '8px' }}
          onClick={handleLearnMore} 
          >
            Learn More<KeyboardArrowRight/>
          </Button>
          </Box>
        </Container>
      </>
    );
  };
  
  export default RealtimeRectifications;