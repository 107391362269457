import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import blogs_5 from "../assets/img/blogs5.png";


const  CostEffectiveAuditing = () => {

  const navigate = useNavigate();

  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }
    return (
      <>
      
        <Container maxWidth="lg">
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img className="ellipse-18" alt="Ellipse" src={blogs_5} title="Code Genie: Cost-Effective Auditing: Exploring Code Genie's Affordability in Blockchain"/></div> <br></br>  <br></br>
            Cost-Effective Auditing: Exploring Code Genie's Affordability in Blockchain
            </Typography>
            <Typography textAlign="justify">
            The blockchain sphere is witnessing an exponential surge in the deployment of smart contracts, paving the way for a new era of digital agreements. However, as the complexity and intricacy of these contracts grow, so does the need for rigorous auditing. Traditionally, this has translated into substantial financial investments, often becoming a deterrent for startups and small projects. Enter Code Genie - an innovative solution that's not only pushing the boundaries of audit precision but also making it economically viable for all. Let's embark on a journey to unpack the affordability of Code Genie's auditing services.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            The Conventional Cost Spectrum
            </Typography>
            <Typography textAlign="justify">
            Before diving into the offerings of Code Genie, it's essential to understand the prevalent cost landscape of smart contract auditing. Traditional manual audits often come with a hefty price tag:
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2}textAlign="justify">
            Expert Fees
            </Typography>
            <Typography textAlign="justify">
            Top-tier blockchain experts command premium fees given their niche expertise.
           </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Extended Timelines
            </Typography>
            <Typography textAlign="justify">
            Manual reviews often span days or weeks, which translates to prolonged engagements and thus higher costs
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Iterative Fixes
            </Typography>
            <Typography textAlign="justify">
            Post-audit fixes, if not included in the initial audit package, can lead to additional expenses.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Code Genie's Affordability Unveiled
            </Typography>
            <Typography textAlign="justify">
            Code Genie has redefined the auditing cost paradigm on multiple fronts
            </Typography>
           <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
           AI-Powered Analysis
            </Typography>
            <Typography textAlign="justify">
            The integration of advanced AI eliminates the need for extensive human hours, thus bringing down costs dramatically. This AI-driven model ensures both speed and precision, offering unparalleled value for money
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Real-time Fixes
            </Typography>
            <Typography textAlign="justify">
            Instead of just pointing out issues, Code Genie proactively suggests fixes, reducing the expenses tied to iterative reviews and subsequent corrections.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Tiered Pricing Models
            </Typography>
            <Typography textAlign="justify">
            Recognizing the diverse needs of the blockchain community, Code Genie offers a range of pricing options. From startups to established entities, there's a cost-effective package for everyone
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Comprehensive Audits
            </Typography>
            <Typography textAlign="justify">
            Instead of charging separately for different audit dimensions like security, functionality, or gas optimization, Code Genie provides a comprehensive package, maximizing ROI
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            The Broader Implications: Accessibility & Innovation
            </Typography>
            <Typography textAlign="justify">
            Beyond the immediate financial savings, the affordability of Code Genie's services has broader implications
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            TDemocratizing Access:
            </Typography>
            <Typography textAlign="justify">
            By slashing auditing costs, Code Genie ensures even the smallest projects can afford top-tier auditing services, democratizing access to quality and safety.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Encouraging Innovation:
            </Typography>
            <Typography textAlign="justify">
            Lower auditing costs mean startups and developers can allocate more resources to innovation, research, and development, propelling the blockchain ecosystem forward. 
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Wrapping Up: A New Era of Smart Contract Auditing
            </Typography>
            <Typography textAlign="justify">
            As blockchain continues to shape the future of digital interactions, ensuring the safety, security, and efficiency of smart contracts is paramount. Code Genie's commitment to affordability ensures that this critical process isn't just reserved for the elite but is accessible to all, irrespective of their financial prowess. In essence, Code Genie isn't just saving costs; it's leveling the playing field, ushering in an era where quality auditing is a standard, not a luxury.
            </Typography>
            {/* <Link
              to="/signin"
              style={{
                display: "flex",
                marginTop: 40,
                textDecoration: "none",
                color: "black",
              }}
            >
              <SvgIcon sx={{ mr: 1 }}>
                <KeyboardBackspace />
              </SvgIcon>
              Back to log in
            </Link> */}
            <Button variant="contained" sx={{ mr: 2, float: 'right', margin: '8px' }}
          onClick={handleLearnMore} 
          >
            Learn More<KeyboardArrowRight/>
          </Button>
          </Box>
        </Container>
      </>
    );
  };
  
  export default CostEffectiveAuditing;