/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Target = ({ color = "white", className }: Props): JSX.Element => {
  return (
    <svg
      className={`target ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16 16L28 4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M24.4875 7.5125C22.3601 5.37869 19.5091 4.12132 16.4989 3.98925C13.4886 3.85717 10.5385 4.86003 8.23238 6.79931C5.92626 8.7386 4.43223 11.473 4.04599 14.4613C3.65976 17.4496 4.40946 20.474 6.14672 22.9358C7.88399 25.3977 10.4822 27.1177 13.4271 27.7554C16.372 28.393 19.449 27.9018 22.049 26.379C24.649 24.8562 26.5826 22.4128 27.4671 19.5324C28.3515 16.652 28.1224 13.5445 26.825 10.825"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M20.2375 11.7625C19.2465 10.7743 17.9407 10.1645 16.5469 10.0389C15.1531 9.91332 13.7593 10.28 12.6077 11.0751C11.456 11.8703 10.6193 13.0437 10.2428 14.3916C9.86639 15.7395 9.97397 17.1767 10.5469 18.4535C11.1198 19.7303 12.1219 20.7661 13.3791 21.3809C14.6363 21.9958 16.0691 22.1508 17.4287 21.8192C18.7883 21.4875 19.9888 20.69 20.8216 19.5653C21.6544 18.4406 22.0669 17.0597 21.9875 15.6625"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

Target.propTypes = {
  color: PropTypes.string,
};
