import { useState, useEffect } from "react";
import {
    Container,
    Box,
    Typography,
    Divider,
    Autocomplete,
    TextField,
    SvgIcon,
    Modal,
    Stack,
    Grid,
    fabClasses,
    Button,
    InputLabel,
    FormControlLabel,
    Switch,
    MenuItem,
    Backdrop
} from "@mui/material";
import { Link } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight, SettingsPowerTwoTone } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IDeleteBlog, IGetBlogsDet } from "../../redux/api/types";
import { useGetBlogDetailsAdminQuery, useBlogDetailsUpdateMutation, useBlogDetailsDraftMutation, useBlogDetailsPublishMutation, useBlogsCountQuery, useGenerateBlogsMutation, useDeleteBlogMutation } from "../../redux/api/promptApi";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as EditIcon } from '../../assets/ico_edit.svg';
import { redirect, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LinkIcon from '@mui/icons-material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import { object, string, TypeOf, number } from "zod";
import { toast } from "react-toastify";
import { ReactComponent as AddIcon } from '../../assets/ico_plus_white.svg';
import { ReactComponent as WishIcon } from "../../assets/ico_wish.svg";
import { ReactComponent as EyeIcon } from "../../assets/ico_eye.svg";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MagicWand from "../../components/MagicWand";

import { updateNamedExports } from "typescript";




interface IGetBlogsAdmin {
    blog_title: string,
    blog_image_string: string,
    blog_summary: string,
    blog_image_path: string,
    cat: string,
    blog_det: string,
    url: string,
    _id: string,
    limitval: number,
    skipval: number,
    query: string,
    serialNumber: number,
    blog_image_path_small: string,
    image_original_id: string,
    image_updated: string,
    mode: string,
    blog_det_draft: string,
    blog_image_path_draft: string,
    blog_summary_draft: string,
    blog_title_draft: string,
    url_draft: string,
    seo_score: number,
    total_views: number,
    rattings: number,
    publish_date: string
}

interface IGenerateContent {
    cat: string,
    title: string,
    prompt: string,
    /*     blog_title_draft: string,
        blog_image_string: string,
        blog_summary_draft: string,
        blog_image_path_draft: string,
        blog_det_draft: string,
        url_draft: string,
        image_update: string,
        mode: string, */
}

interface BlogPreviewResponseStruct {
    data: {
        msg: BlogPreviewResponseStructMsg
    };
    status: string;
}

interface BlogPreviewResponseStructMsg {
    blog_det_draft: string;
    blog_image_path_draft: string;
    blog_image_string: string;
    blog_summary_draft: string;
    blog_title_draft: string;
    cat: string;
    image_updated: string;
    mode: string;
    url_draft: string;
    blogid: string;
};


const generateContent = object({
    cat: string(),
    title: string(),
    prompt: string(),
})

const blogUpdate = object({
    id: string(),
    blog_title: string(),
    blog_image_string: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    publish_date: string()
})

const blogDraft = object({
    id: string(),
    blog_title: string(),
    blog_image_string: string(),
    blog_image: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    author: string(),
    author_image: string(),
    author_url: string(),
    cta_label: string(),
    cta_url: string()
})

const blogPublish = object({
    id: string(),
    blog_title: string(),
    blog_image_string: string(),
    blog_image: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    author: string(),
    author_image: string(),
    author_url: string(),
    cta_url: string(),
    cta_label: string(),
    
})

export type BlogUpdate = TypeOf<typeof blogUpdate>
export type BlogDraft = TypeOf<typeof blogDraft>
export type BlogPublish = TypeOf<typeof blogPublish>

export type GenerateCont = TypeOf<typeof generateContent>




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: '#1377C3', 
        color: 'lightblack',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'white', // Set background color for odd rows to white
        color: 'black', // Set text color for odd rows to black
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#F2F6FA', // Set background color for even rows to gray
        color: 'darkslategray',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const customInputStyle = {
    color: 'Black',
    border: '1px solid #CBCBCB',
};


const ContentEditor = () => {



    return (
        <></>
    );
};

export default ContentEditor;
