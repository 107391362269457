import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import image_17 from "../assets/img/image17.png";


const BeyondSecurity = () => {

  const navigate = useNavigate();
  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }
    return (
      <>
      
        <Container maxWidth="lg">
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img className="ellipse-18" alt="Ellipse" src={image_17} title="Code Genie: Beyond Security: The Multi-Dimensional Benefits of Code Genie’s Audits"/></div> <br></br>  <br></br>
            Beyond Security: The Multi-Dimensional Benefits of Code Genie’s Audits
            </Typography>
            <Typography textAlign="justify">
            At the heart of the decentralized revolution, smart contracts are changing the way we transact, trust, and transform digital agreements. Given their pivotal role in underpinning a myriad of applications, the security of these contracts naturally comes to the forefront. However, while security remains paramount, it's merely one dimension of a broader spectrum that defines a robust smart contract. With Code Genie's innovative approach to audits, the horizon expands beyond mere security checks, unveiling a comprehensive suite of benefits that position smart contracts for optimal performance and longevity.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Gas Usage Optimization: Fueling Efficiency
            </Typography>
            <Typography textAlign="justify">
            One of the most common pain points in the blockchain space is the excessive gas fees, which often deter users and hinder transaction efficiency. Code Genie doesn’t just audit for vulnerabilities but also delves deep into the code to pinpoint areas where gas usage can be optimized. The result? Faster transaction times, reduced costs, and an enhanced user experience.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2}textAlign="justify">
            Upgradability: Future-Proofing Contracts
            </Typography>
            <Typography textAlign="justify">
            In the rapidly evolving world of blockchain, adaptability is crucial. A smart contract that works flawlessly today might need adjustments tomorrow. Code Genie recognizes this dynamic nature and ensures that contracts are not just robust but also upgradable. This ensures longevity and adaptability, safeguarding investments for the long haul

            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Comprehensive Event Logging: Transparency at its Best
            </Typography>
            <Typography textAlign="justify">
            Transparency is one of the foundational pillars of blockchain technology. Code Genie takes this a notch higher by ensuring comprehensive event logging within the contracts. This facilitates easier tracking, debugging, and enhances trust among stakeholders, ensuring every action within the contract is traceable and accountable.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Fallback Mechanisms: Preparedness for the Unforeseen
            </Typography>
            <Typography textAlign="justify">
            In the unpredictable realm of decentralized networks, having robust fallback mechanisms is invaluable. Code Genie ensures that contracts are equipped with these safety nets, making sure that in the event of unforeseen issues, there's a systematic, predefined way to address them, minimizing disruptions and potential losses.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Beyond Detection: Proactive Rectification
            </Typography>
            <Typography textAlign="justify">
            What sets Code Genie apart from traditional auditing tools is not just its ability to detect issues but to proactively offer solutions. Its AI-driven engine doesn’t just stop at identifying vulnerabilities; it suggests real-time fixes, ensuring developers have actionable insights at their fingertips
            </Typography>
    
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            A Holistic Vision for Smart Contracts
            </Typography>
            <Typography textAlign="justify">
            Code Genie’s approach to audits is a testament to the multi-dimensional nature of smart contracts. While security remains the bedrock, several other facets – from efficiency and adaptability to transparency and resilience – come together to define a truly robust contract. With its comprehensive audits, Code Genie isn't just ensuring the safety of contracts; it's setting them up for success, longevity, and optimal performance.
            In wrapping up, as we traverse deeper into the era of decentralization, tools like Code Genie ensure that our journey is not just safe but also efficient, adaptable, and forward-looking. It's not just about building smart contracts; it's about building them right, and with Code Genie, we're one step closer to that ideal.
            </Typography>
            {/* <Link
              to="/signin"
              style={{
                display: "flex",
                marginTop: 40,
                textDecoration: "none",
                color: "black",
              }}
            >
              <SvgIcon sx={{ mr: 1 }}>
                <KeyboardBackspace />
              </SvgIcon>
              Back to log in
            </Link> */}
            <Button variant="contained" sx={{ mr: 2, float: 'right', margin: '8px' }}
          onClick={handleLearnMore} 
          >
            Learn More<KeyboardArrowRight/>
          </Button>
          </Box>
        </Container>
      </>
    );
  };
  
  export default BeyondSecurity;