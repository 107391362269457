import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import blogs_6 from "../assets/img/blogs6.png";


const ANoviceInBlockchain = () => {
  const navigate = useNavigate();
  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }
    return (
      <>
      
        <Container maxWidth="lg">
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img className="ellipse-18" alt="Ellipse" src={blogs_6} title="Code Genie: A Novice in Blockchain? Here's Why Code Genie is Your Best Bet" /></div> <br></br>  <br></br>
            A Novice in Blockchain? Here's Why Code Genie is Your Best Bet
            </Typography>
            <Typography textAlign="justify">
            Blockchain, with its disruptive potential, is luring enthusiasts from all walks of life. But as novices dive deep into this ocean of possibilities, they often find themselves grappling with the complexities of smart contracts. These self-executing contracts, albeit powerful, require meticulous attention to detail. The stakes are high, and the margin for error? Minuscule. If you find yourself tiptoeing on the edge of this intimidating precipice, Code Genie is here to offer a safety net. Here's how this revolutionary tool ensures that even those without a coding background can navigate the smart contract maze with confidence.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Intuitive Interface: No Jargon, Just Results
            </Typography>
            <Typography textAlign="justify">
            Code Genie's design philosophy centers around user-friendliness. The platform strips away intimidating technical jargon, presenting users with an intuitive interface that guides even the most uninitiated through the auditing process. With a simple “Audit Contract” button and no prompts, the daunting task of auditing becomes as simple as a click.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2}textAlign="justify">
            One-Click Audit: Simplicity at its Finest
            </Typography>
            <Typography textAlign="justify">
            The beauty of Code Genie lies in its simplicity. Forget about trawling through lines of code or understanding intricate programming paradigms. With just a single click, Code Genie initiates a comprehensive audit of your smart contract, handling the complexities behind the scenes while you wait for the results.
           </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            AI-Powered Precision: Expertise Without the Expert
            </Typography>
            <Typography textAlign="justify">
            The power of Code Genie is anchored in its AI-driven engine. This ensures that even if you're unfamiliar with the technical nuances of smart contracts, you're still backed by an AI "expert" that scrutinizes every line of your contract with unparalleled precision. This AI isn't just replicating human expertise; it's enhancing it, catching vulnerabilities and inefficiencies that might slip past even seasoned professionals.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Real-Time Fixes: Beyond Identification
            </Typography>
            <Typography textAlign="justify">
            For many novices, identifying an issue in a smart contract is only half the battle. The real challenge lies in rectification. Code Genie bridges this gap, offering real-time fixes for detected vulnerabilities. So, you aren't just made aware of potential problems; you're handed solutions on a silver platter.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Continuous Learning: Grow with Code Genie
            </Typography>
            <Typography textAlign="justify">
            Every interaction with Code Genie is a learning experience. As the platform identifies and rectifies issues, users get insights into common vulnerabilities, best practices, and optimization strategies. Over time, even without formal coding education, users build a foundational understanding of what makes a robust smart contract.
            </Typography>
           <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
           In Conclusion: Embracing Blockchain, Fearlessly
            </Typography>
            <Typography textAlign="justify">
            For many, the world of blockchain represents uncharted territory. While the promise of decentralized technologies is enticing, the challenges can be off-putting. Code Genie, with its commitment to simplicity and education, ensures that everyone, irrespective of their technical prowess, can participate in the blockchain revolution confidently. So, if you're a novice looking to explore the wonders of smart contracts, remember: with Code Genie by your side, you're in safe hands.
            </Typography>
            
            {/* <Link
              to="/signin"
              style={{
                display: "flex",
                marginTop: 40,
                textDecoration: "none",
                color: "black",
              }}
            >
              <SvgIcon sx={{ mr: 1 }}>
                <KeyboardBackspace />
              </SvgIcon>
              Back to log in
            </Link> */}
            <Button variant="contained" sx={{ mr: 2,/*  backgroundColor: '#198754', */ float: 'right', margin: '8px' }}
          onClick={handleLearnMore} 
          >
            Learn More<KeyboardArrowRight/>
          </Button>
          </Box>
        </Container>
      </>
    );
  };
  
  export default ANoviceInBlockchain;