import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  Container,
  Stack,
  Grid,
  TextField,
  SvgIcon,
  IconButton,
  MenuItem,
  Autocomplete,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { object, string, TypeOf, array, number } from "zod";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";

import { ReactComponent as DeleteIcon } from "../../assets/ico_del.svg";
import { ReactComponent as PlustIcon } from "../../assets/ico_plus.svg";
import { IPlanDetail, IProductHeadings } from "../../redux/api/types";
import {
  useLazyGetProductQuery,
  useLazySearchProductQuery,
  useUpdatePriceMutation,
} from "../../redux/api/productApi";

const saveSchema = object({
  product_name: string().min(1, "Product name is required"),
  product_module: string().optional(),
  module_description: string().optional(),
  plan_details: array(
    object({
      user: number(),
      plan_name: string(),
      total_wishes: number().optional(),
      price: string(),
      period: string(),
      product_id: string(),
      price_id: string(),
      subscription_level: string(),
      type: string(),
      button_text: string(),
    })
  ),
});

export type PriceSettingSaveInput = TypeOf<typeof saveSchema>;

const PriceConfigurator = () => {
  const [options, setOptions] = useState<IProductHeadings[]>([]);
  const [filter, setFilter] = useState<IProductHeadings | null>(null);
  const [plans, setPlans] = useState<IPlanDetail[]>([
    {
      user: 1,
      plan_name: "",
      total_wishes: 0,
      price: "",
      period: "",
      product_id: "",
      price_id: "",
      subscription_level: "",
      feature: [],
      contracts: 0,
      type: "",
      button_text: "",
    },
  ]);

  const methods = useForm<PriceSettingSaveInput>({
    resolver: zodResolver(saveSchema),
    defaultValues: {},
  });

  const customInputStyle = {
    color: 'Black',
    border: '1px solid #CBCBCB',
  };
  const [searchProduct, searchState] = useLazySearchProductQuery();
  const [getProduct, getState] = useLazyGetProductQuery();
  const [updatePrice, updateState] = useUpdatePriceMutation();

  const { handleSubmit, register, setValue } = methods;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette
      .mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    searchProduct("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchState.data) setOptions(searchState.data);
  }, [searchState]);

  useEffect(() => {
    if (updateState.isSuccess)
      toast.success("Price plan updated successfully!");
  }, [updateState]);

  useEffect(() => {
    const { data } = getState;
    setValue("product_name", data?.product_name as string);
    setValue("product_module", data?.product_module as string);
    setValue("module_description", data?.module_description as string);
    if (data?.plan_details) setPlans([...data.plan_details]);
    else
      setPlans([
        {
          user: 1,
          plan_name: "",
          total_wishes: 0,
          price: "",
          period: "",
          product_id: "",
          price_id: "",
          subscription_level: "",
          feature: [],
          contracts: 0,
          type: "",
          button_text: "",
        },
      ]);
  }, [getState, setValue]);

  const onSubmitHandler: SubmitHandler<PriceSettingSaveInput> = (
    values: PriceSettingSaveInput
  ) => {
    //console.log(values);
    updatePrice(values);
  };

  return (
    <>
      <div className="body-17-1">
        <Box paddingY={5}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              background:
                "-webkit-linear-gradient(139deg, #036AB7 0%, #49A4EA 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              letterSpacing: "-0.8px",
              fontWeight: "bold",
            }}
          >
            Pricing Configurator
          </Typography>
        </Box>
        <Divider />
        <Container>
          <Stack marginTop={5} spacing={2}>
            <Stack alignItems="end">
              <Autocomplete
                options={options}
                noOptionsText="No Products"
                sx={{ width: 216 }}
                renderInput={(params) => (
                  <TextField
                  sx={{border: '1px solid #CBCBCB'}}
                    {...params}
                    // label="Search Products"
                    placeholder="Search Products"
                    fullWidth
                    size="small"
                  />
                )}
                getOptionLabel={(option) =>
                  `${option.product_name} : ${option.product_module}`
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      {option.product_name} : {option.product_module}
                    </li>
                  );
                }}
                value={filter}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFilter(newValue);
                    getProduct(newValue);
                  }
                }}
              />
            </Stack>
            <Box
              padding={4}
              border="1px solid #CACBCC"
              borderRadius={1}
              component="form"
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
            >
              <Stack spacing={5}>
                <Stack flexDirection="row">
                  <Typography
                    fontWeight="bold"
                    variant="h6"
                    color="black"
                    flexGrow={1}
                  >
                    Configure pricing here
                  </Typography>
                  <Stack flexDirection="row">
                    <LoadingButton
                      variant="contained"
                      sx={{ width: 152, paddingY: 1 }}
                      loading={updateState.isLoading}
                      type="submit"
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Stack>
                <FormProvider {...methods}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography
                        color="black"
                        variant="h6"
                        fontWeight={400}
                      >
                        Product Name
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        InputProps={{
                          style: customInputStyle,
                        }}
                        sx={{ width: 350 }}
                        {...register("product_name")}
                        disabled
                        required
                        variant="outlined"
                        placeholder="Enter Product name here."
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography
                        color="black"
                        variant="h6"
                        fontWeight={400}
                      >
                        Product Module
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      gap={2}
                      display="flex"
                      flexDirection="column"
                    >
                      <TextField
                        InputProps={{
                          style: customInputStyle,
                        }}
                        {...register("product_module")}
                        required
                        sx={{ width: 350 }}
                        placeholder="Enter Product module name here."

                      />
                      <TextField
                        InputProps={{
                          style: customInputStyle,
                        }}
                        {...register("module_description")}
                        required
                        placeholder="Write Product module description here."
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography
                        color="black"
                        variant="h6"
                        fontWeight={400}
                      >
                        Plan Details
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      gap={2}
                      display="flex"
                      flexDirection="column"
                    >
                      {/*<Stack flexDirection="row" justifyContent="space-between" flexWrap="wrap">
                      <Typography width={196}>Plan Name</Typography>
                      <Typography width={128}>Total Wishes</Typography>
                      <Typography width={90}>Price</Typography>
                      <Typography width={90}>Product Id</Typography>
                      <Typography width={90}>Price Id</Typography>
                      <Typography width={90}>Subscription Level</Typography>
                      <Typography width={159}>Period</Typography>
                      <Typography width={159}>Type</Typography>
                      <Typography width={159}>Button Text</Typography>
                      <Box width={68}></Box>
                    </Stack>*/}
                      <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        flexWrap="wrap"
                      >
                        {plans.map((plan, index) => (
                          <Item key={`plan_item_${index}`} style={{ margin: "5px", backgroundColor: '#F9FBFC' }}>
                            <TextField
                              {...register(`plan_details.${index}.plan_name`)}
                              InputProps={{
                                style: customInputStyle,
                              }}
                              placeholder="Plan Name"
                              label="Plan Name"
                              sx={{ width: 150, margin: "5px" }}
                              value={plan.plan_name}
                              onChange={(e) => {
                                plans[index].plan_name = e.target.value;
                                setPlans([...plans]);
                              }}
                            />
                            <TextField
                              {...register(`plan_details.${index}.total_wishes`, {
                                valueAsNumber: true,
                              })}
                              InputProps={{
                                style: customInputStyle,
                              }}
                              type="number"
                              placeholder="Total Wishes"
                              label="Total Wishes"
                              sx={{ width: 150, margin: "5px" }}
                              value={plan.total_wishes}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                plans[index].total_wishes = e.target.valueAsNumber;
                                setPlans([...plans]);
                              }}
                            />
                            <TextField
                              {...register(`plan_details.${index}.price`)}
                              InputProps={{
                                style: customInputStyle,
                              }}
                              placeholder="Price"
                              label="Price"
                              sx={{ width: 150, margin: "5px" }}
                              value={plan.price}
                              onChange={(e) => {
                                plans[index].price = e.target.value;
                                setPlans([...plans]);
                              }}
                            />
                            <TextField
                              {...register(`plan_details.${index}.product_id`)}
                              InputProps={{
                                style: customInputStyle,
                              }}
                              placeholder="Product id"
                              label="Product id"
                              sx={{ width: 150, margin: "5px" }}
                              value={plan.product_id}
                              onChange={(e) => {
                                plans[index].product_id = e.target.value;
                                setPlans([...plans]);
                              }}
                            />
                            <TextField
                              {...register(`plan_details.${index}.price_id`)}
                              InputProps={{
                                style: customInputStyle,
                              }}
                              placeholder="Price id"
                              label="Price id"
                              sx={{ width: 150, margin: "5px" }}
                              value={plan.price_id}
                              onChange={(e) => {
                                plans[index].price_id = e.target.value;
                                setPlans([...plans]);
                              }}
                            />
                            <TextField
                              {...register(`plan_details.${index}.subscription_level`)}
                              InputProps={{
                                style: customInputStyle,
                              }}
                              placeholder="Subscription Level"
                              label="Subscription Level"
                              sx={{ width: 150, margin: "5px" }}
                              value={plan.subscription_level}
                              onChange={(e) => {
                                plans[index].subscription_level = e.target.value;
                                setPlans([...plans]);
                              }}
                            />
                            <TextField
                              {...register(`plan_details.${index}.period`)}
                              InputProps={{
                                style: customInputStyle,
                              }}
                              select
                              label="Period"
                              sx={{ width: 150, margin: "5px" }}
                              value={plan.period}
                              onChange={(e) => {
                                plans[index].period = e.target.value;
                                setPlans([...plans]);
                              }}
                            >
                              <MenuItem value="monthly">Monthly</MenuItem>
                              <MenuItem value="yearly">Yearly</MenuItem>
                            </TextField>
                            <TextField
                              {...register(`plan_details.${index}.type`)}
                              InputProps={{
                                style: customInputStyle,
                              }}
                              select
                              label="Type"
                              sx={{ width: 150, margin: "5px" }}
                              value={plan.type}
                              onChange={(e) => {
                                plans[index].type = e.target.value;
                                setPlans([...plans]);
                              }}
                            >
                              <MenuItem value="onetime">One Time</MenuItem>
                              <MenuItem value="subscription">Subscription</MenuItem>
                              <MenuItem value="addon">Add On</MenuItem>
                            </TextField>
                            <TextField
                              {...register(`plan_details.${index}.button_text`)}
                              InputProps={{
                                style: customInputStyle,
                              }}
                              placeholder="Button Text"
                              label="Button Text"
                              sx={{ width: 150, margin: "5px" }}
                              value={plan.button_text}
                              onChange={(e) => {
                                plans[index].button_text = e.target.value;
                                setPlans([...plans]);
                              }}
                            />
                            <Stack
                              flexDirection="row"
                              justifyContent="flex-end"
                              alignItems="flex-end"
                            >
                              <IconButton
                                sx={{ width: 30, height: 30 }}
                                onClick={() => {
                                  plans.splice(index, 1);
                                  if (plans.length === 0)
                                    setPlans([
                                      {
                                        user: 1,
                                        plan_name: "",
                                        total_wishes: 0,
                                        price: "",
                                        period: "",
                                        product_id: "",
                                        price_id: "",
                                        subscription_level: "",
                                        feature: [],
                                        contracts: 0,
                                        type: "",
                                        button_text: "",
                                      },
                                    ]);
                                  else setPlans([...plans]);
                                }}
                              >
                                <SvgIcon>
                                  <DeleteIcon />
                                </SvgIcon>
                              </IconButton>
                              {index === plans.length - 1 && (
                                <IconButton
                                  sx={{ width: 30, height: 30 }}
                                  onClick={() =>
                                    setPlans([
                                      ...plans,
                                      {
                                        user: 1,
                                        plan_name: "",
                                        total_wishes: 0,
                                        price: "",
                                        period: "",
                                        product_id: "",
                                        price_id: "",
                                        subscription_level: "",
                                        feature: [],
                                        contracts: 0,
                                        type: "",
                                        button_text: "",
                                      },
                                    ])
                                  }
                                >
                                  <SvgIcon>
                                    <PlustIcon />
                                  </SvgIcon>
                                </IconButton>
                              )}
                            </Stack>
                          </Item>
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>
                </FormProvider>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </div>
    </>
  );
};

export default PriceConfigurator;
