import { Outlet } from "react-router-dom";
import Header from "./headers/Header";
import AdminHeader from "./headers/AdminHeader";
import PricingHeader from "./headers/PricingHeader";
import Footer from "./footer/footer";

const Layout = (props: { type: "admin" | "user" , page: "pricing" | ""}) => {
  return (
    <>
      {props.type === "admin" ? <AdminHeader /> : props.page === "pricing"?<PricingHeader />:<Header />}
      <Outlet />
    </>
  );
};

export default Layout;
