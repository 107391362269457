import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import blogs_12 from "../assets/img/blogs12.png";


const TheEthicalEdge = () => {

  const navigate = useNavigate();

  const handleLearnMore = () =>{
    navigate('/codegenie/smart_contracts');
  }
    return (
      <>
      
        <Container maxWidth="lg">
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img className="ellipse-18" alt="Ellipse" src={blogs_12} title="Code Genie: The Ethical Edge: Code Genie's Commitment to Transparent Auditing"/></div> <br></br>  <br></br>
            The Ethical Edge: Code Genie's Commitment to Transparent Auditing
            </Typography>
            <Typography textAlign="justify">
            In the digital age, as the blockchain realm expands at a blistering pace, trust becomes the bedrock of all technological interactions. The blockchain, lauded for its immutable transparency, is no exception. Yet, as layers of complexities unfold, ensuring transparency, especially in areas like smart contract auditing, poses a challenge. Enter Code Genie, the beacon of ethical, transparent, and honest smart contract auditing. This tool stands tall not just for its technical prowess but also for its unwavering commitment to ethical standards.   
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            A Higher Standard: The Core Ethical Principles
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Transparency Above All
            </Typography>
            <Typography textAlign="justify">
            At the heart of Code Genie's operations is a steadfast commitment to transparent auditing. By providing users with a clear and comprehensive breakdown of each audit, stakeholders can gain valuable insights into the inner workings of their smart contracts, making informed decisions about their blockchain ventures.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2}textAlign="justify">
            No Hidden Agendas
            </Typography>
            <Typography textAlign="justify">
            One of the pitfalls many fall into in the world of blockchain is hidden biases. Code Genie guarantees an unbiased audit every time. It operates without vested interests, ensuring every smart contract is assessed purely on its merits and vulnerabilities.
           </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Data Privacy and Security
            </Typography>
            <Typography textAlign="justify">
            With rising concerns over data breaches and misuse, Code Genie champions the cause of data privacy. It assures users that their smart contract codes and any associated smart contract data are not stored by Code Genie therefore eliminating the risk from any security breach
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Building Confidence in the Blockchain Ecosystem
            </Typography>
            <Typography textAlign="justify">
            The trust factor in blockchain extends beyond just technology. Stakeholders, investors, and users seek confidence not just in code but in the very processes that assess this code. Code Genie's transparent approach to smart contract auditing is aimed at bolstering this very confidence.
             By adopting a rigorous, transparent, and ethical methodology, Code Genie ensures that every user, from blockchain novices to industry veterans, can trust the audit results. The transparency also fosters a sense of responsibility and accountability, encouraging developers and teams to strive for the best coding practices.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Driving Change in the Industry
            </Typography>
            <Typography textAlign="justify">
            While the blockchain industry is burgeoning with potential, it's essential to remember that it's still in its formative years. Pioneering tools like Code Genie not only set high standards but also shape the very ethos of the industry. By placing a premium on ethical practices, Code Genie indirectly urges other platforms and services in the blockchain arena to follow suit.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            A Future Rooted in Ethical Practices
            </Typography>
            <Typography textAlign="justify">
            As the blockchain landscape continues to evolve, tools like Code Genie will play a pivotal role in dictating the course. The onus isn't just on technological advancements but on merging these advancements with ethical practices.
            The commitment to transparency and ethical standards by Code Genie heralds a brighter, more trustworthy future for the blockchain domain. For stakeholders looking to dive deep into the blockchain, tools grounded in ethics like Code Genie ensure that their journey is not only technologically sound but also ethically fortified.
            To stakeholders navigating the complex terrains of blockchain, remember: in a world of codes and contracts, it's the ethical foundations that stand the test of time. Dive into the world of Code Genie and experience the confluence of top-tier technology and unwavering ethical standards.
            </Typography>
            {/* <Link
              to="/signin"
              style={{
                display: "flex",
                marginTop: 40,
                textDecoration: "none",
                color: "black",
              }}
            >
              <SvgIcon sx={{ mr: 1 }}>
                <KeyboardBackspace />
              </SvgIcon>
              Back to log in
            </Link> */}
            <Button variant="contained" sx={{ mr: 2, float: 'right', margin: '8px' }}
          onClick={handleLearnMore} 
          >
            Learn More<KeyboardArrowRight/>
          </Button>
          </Box>
        </Container>
      </>
    );
  };
  
  export default TheEthicalEdge;