import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowRight } from "@mui/icons-material";
import blogs_7 from "../assets/img/blogs7.png";


const   OneClickMultipleSolutions = () => {

  const navigate = useNavigate();
  const handleLearnMore = () => {
    navigate('/codegenie/smart_contracts');
  }
    return (
      <>
      
        <Container maxWidth="lg">
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img className="ellipse-18" alt="Ellipse" src={blogs_7} title="Code Genie: One Click, Multiple Solutions: The Comprehensive Auditing of Code Genie"/></div> <br></br>  <br></br>
            One Click, Multiple Solutions: The Comprehensive Auditing of Code Genie
            </Typography>
            <Typography textAlign="justify">
            The blockchain universe is rapidly expanding, and as it does, the complexity of navigating its intricacies grows in tandem. From startups to seasoned developers, everyone is in search of a tool that simplifies the labyrinthine world of smart contracts. Enter Code Genie, the unparalleled smart contract auditing solution that promises not just detection but holistic enhancement. With its one-click audit feature, Code Genie is revolutionizing the way we approach blockchain efficiency, security, and functionality. Let's delve deeper into how Code Genie's comprehensive auditing is setting new standards in the blockchain landscape.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify" >
            Efficiency Optimization with Code Genie
            </Typography>
            <Typography textAlign="justify">
            The blockchain ecosystem thrives on efficiency. Faster transactions and optimized gas usage are more than just buzzwords; they're the backbone of a seamless blockchain experience. Code Genie’s one-click audit scans smart contracts for inefficiencies, ensuring that the code is streamlined and gas-optimized. This not only reduces transaction costs but also enhances the overall user experience, making your smart contract more appealing and user-friendly in the decentralized marketplace.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2}textAlign="justify">
            Fortifying Security Barriers
            </Typography>
            <Typography textAlign="justify">
            Security breaches in the blockchain realm can be catastrophic. From financial losses to compromised data integrity, the repercussions are manifold. Recognizing this, Code Genie's comprehensive audit places a premium on security. With a single click, it delves into the depths of your smart contract code, identifying vulnerabilities, potential exploit scenarios, and weak points that could be targeted by malicious actors. Armed with this insight, developers can fortify their smart contracts against potential threats, ensuring airtight security.
           </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            Enhancing Functional Capabilities
            </Typography>
            <Typography textAlign="justify">
            A smart contract's true value is derived from its functionality. Code Genie understands this, and its one-click audit isn't just about finding flaws; it’s about amplifying capabilities. By analyzing the smart contract’s core functionalities, Code Genie provides actionable insights and real-time fixes that enhance its utility, ensuring that the contract not only performs its intended function but does so at an optimal level.
            </Typography>
            <Typography variant="h4" color="text.secondary" mt={5} mb={2} textAlign="justify">
            A Holistic View: Beyond the Code
            </Typography>
            <Typography textAlign="justify">
            While Code Genie is rooted in code analysis, its vision is broader. The platform offers insights into event logs, contract ownership structures, and upgradability prospects. This ensures that every facet of the smart contract, from its foundational code to its operational dynamics, is optimized and future-ready. For developers and organizations aiming to establish a significant footprint in the decentralized world, Code Genie’s holistic auditing approach offers a competitive edge that’s hard to rival.
            </Typography>
            {/* <Link
              to="/signin"
              style={{
                display: "flex",
                marginTop: 40,
                textDecoration: "none",
                color: "black",
              }}
            >
              <SvgIcon sx={{ mr: 1 }}>
                <KeyboardBackspace />
              </SvgIcon>
              Back to log in
            </Link> */}
            <Button variant="contained" sx={{ mr: 2, float: 'right', margin: '8px' }}
          onClick={handleLearnMore} 
          >
            Learn More<KeyboardArrowRight/>
          </Button>
          </Box>
        </Container>
      </>
    );
  };
  
  export default OneClickMultipleSolutions;